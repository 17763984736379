<template>
  <v-card
    class="mx-auto px-2 py-5 pa-md-10 text-center"
    elevation="5"
    width="100%"
    max-width="40rem"
    rounded="lg"
    :style="`background-color: ${layerColor}`"
  >
    <div class="mb-10">
      <IcoContractRejected type="error" />
    </div>

    <v-card-title>
      <h1 class="headline font-weight-bold mx-auto mb-0">
        {{ $t('contract.deleted') }}
      </h1>
    </v-card-title>

    <v-card-text>
      <p class="font-weight-regular mb-0">
        {{ $t('contract.not_exist') }}
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import IcoContractRejected from '@/components/svg/IcoContractRejected.vue';

export default {
  name: 'ExpiredContract',
  components: {
    IcoContractRejected,
  },
  computed: {
    ...mapGetters({
      layerColor: 'publicContract/layerColor',
    }),
  },
};
</script>

<style lang="scss" scoped></style>
